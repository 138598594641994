document.addEventListener('readystatechange', (event) => {
  if (event.target.readyState === 'complete') {
    const hash = window.location.hash;
    // Check if there's a hash and it's not just '#'
    if (hash && hash !== '#') {

      // Find the element with the id that matches the hash
      const targetElement = document.querySelector(hash);
      if (targetElement) {

        const headerHeight = document.getElementById('shopify-section-header').offsetHeight;
        const elementPosition = targetElement.getBoundingClientRect().top;

        // Calculate the position to scroll to (element position - header height)
        const offsetPosition = elementPosition - headerHeight;

        // Scroll to the calculated position with smooth behavior
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }
});